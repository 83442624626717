export default {
    methods: {
        loadData: function(data, callback) {
            this.$store.dispatch('getNewsList', data)
            .then((rs) => {
                callback && callback({
                    list: rs.news.list || [],
                    total: rs.news.total || 0,
                    keywords: rs.hotkeywords,
                }, null)
            }).catch((rs) => {
                callback && callback(null, rs)
            })
        }
    }
}