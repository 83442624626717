<template>
    <div id="news-list">
        <dl class="breadcrumb" v-if="breadcrumb">
			<dt>您當前位置:</dt>
			<dd><a href="/news/list">健康資訊</a><i>/</i></dd>
			<dd><span v-text="breadcrumb"></span></dd>
		</dl>
        <div class="news-list">
            <div class="news-item" v-for="news in dataList" :key="news.newsid">
                <div class="news-item-t">
                    <span class="news-category" @click="onCategoryClick">
                        <router-link :to="`/news/list?CatID=${news.catid}`" v-text="'#'+news.category">
                        </router-link>
                    </span>
                    <h2 class="news-title"><router-link :to="'/news/detail/'+news.newsid" v-text="news.title"></router-link></h2>
                </div>
                <p class="news-content" v-text="news.introduce"></p>
                <div class="news-item-b">
                    <div class="news-tags">
                        <router-link :to="'/news/list?keyword='+tag" :class="{'news-tag': true, 'hide': !tag}" v-for="(tag, i) in ((news.tag||'').split(/\||,|，/)).splice(0,5)" :key="i">{{tag}}</router-link>
                    </div>
                    <div class="news-type" v-text="news.createdtime"></div>
                </div>
            </div>
            <split-page v-if="visible" :initData="pageData" :onPageChanged="onPageChanged"/>
        </div>
        <loading v-if="loading"/>
    </div>
</template>

<script>
    import news_list from '@/views/mixins/news_list'
    import SplitPage from '@/layouts/SplitPage.vue'
    import Loading from '@/layouts/Loading.vue'
	export default {
        mixins:[
            news_list,
        ],
        components: {
            SplitPage,
            Loading
        },
        props: {
            kk: null,
            callback: Function,
        },
        data: function(){
            return {
                category: null,
                keyword: undefined,
                categoryid: undefined,
                loading: false,
                visible: false,
                pageData: {
                    pageNo: 1,
                    pageSize: 10,
                    total: 0,
                },
                dataList: [],
                pageSize: 10,
                pageNo: 1,
                total: 0,
                clientId: this.$store.getters.clientId,
                breadcrumb: '',
            }
        },
        mounted: function(){
            var userinfo = this.$store.getters.userinfo
            var hash = this.$route.hash
            hash = hash.replace('#', '')
            var list = hash.split('&')
            var key = list[0].split('=')[0]
            var value = list[0].split('=')[1] / 1
            if(key == 'page') {
                if(! isNaN(value)) {
                    this.pageNo = parseInt(value) < 1 ? 1 : parseInt(value)
                }
            }
            this.keyword = this.$route.query.keyword || undefined
            this.categoryid = this.$route.query.CatID || undefined
            this.breadcrumb = this.keyword ? ('搜索：'+this.keyword) : ''
            this.onRefresh()
        },
        methods: {
            onCategoryClick: function(){
                if(!this.loading) this.onRefresh()
            },
            onPageChanged: function(pageNo){
                this.categoryid = this.$route.query.CatID || undefined
                this.keyword = this.$route.query.keyword || undefined

                var paramList = []
                if(this.categoryid) {
                    paramList.push(`CatID=${this.categoryid}`)
                }
                if(this.keyword) {
                    paramList.push(`keyword=${this.keyword}`)
                }
                var path = this.$route.path
                if(paramList.length) {
                    path = `${path}?${paramList.join('&')}`
                }
                path = `${path}#page=${pageNo}`
                this.$router.push({path: path},(e)=>{})
            },
            onRefresh: function(){
                if(this.total > 0 && this.pageNo > 0) {
                    var maxPage = Math.floor((this.total + this.pageSize - 1) / this.pageSize)
                    if(maxPage > 0 && maxPage < this.pageNo) {
                        var path = this.$route.fullPath
                        var hash = this.$route.hash
                        if(hash && path.indexOf(hash) != -1) {
                            path = path.replace(hash, '')
                        }
                        this.$router.push({path: `${path}#page=1`},(e)=>{})
                        return
                    }
                }
                this.loading = true
                this.loadData({
                    clientId: this.clientId,
                    pageNo: this.pageNo,
                    pageSize: this.pageSize,
                    keyword: this.keyword || undefined,
                    catid: this.categoryid || undefined
                }, (data, error)=>{
                    this.loading = false
                    if(data) {
                        this.dataList = data.list || []
                        this.total = data.total || []
                        var keywords = data.keywords
                        if(keywords) {
                            this.callback && this.callback(keywords)
                        }
                        this.pageData = {
                            pageNo: this.pageNo,
                            pageSize: this.pageSize,
                            total: this.total,
                        }
                        this.visible = this.total > 0
                        if(!this.keyword && this.categoryid && this.dataList.length) {
                            this.breadcrumb = '分類：' + this.dataList[0].category
                        }
                        var maxPage = Math.floor((this.total + this.pageSize - 1) / this.pageSize)
                        if(maxPage > 0 && maxPage < this.pageNo) {
                            var path = this.$route.fullPath
                            var hash = this.$route.hash
                            if(hash && path.indexOf(hash) != -1) {
                                path = path.replace(hash, '')
                            }
                            this.$router.push({path: `${path}#page=1`},(e)=>{})
                            return
                        }
                    } else {
                        if(error) {
                            this._toast.warning(this, error)
                        }
                    }
                })
            }
        },
        watch: {
            $route(to,from){
                var hash = this.$route.hash
                this.pageNo = 1
                var visible = false
                if(hash) {
                    hash = hash.replace('#', '')
                    var list = hash.split('&')
                    var key = list[0].split('=')[0]
                    var value = list[0].split('=')[1] / 1
                    if(key == 'page') {
                        if(!isNaN(value)) {
                            this.pageNo = parseInt(value) < 1 ? 1 : parseInt(value)
                            visible = this.visible
                        }
                    }
                }

                this.pageData = {
                    pageNo: this.pageNo,
                    pageSize: this.pageSize,
                    total: this.total,
                }
                this.visible = visible
                this.keyword = this.$route.query.keyword || undefined
                this.categoryid = this.$route.query.CatID || undefined

                var path = this.$route.path
                var paramList = []
                if(this.categoryid) {
                    paramList.push(`CatID=${this.categoryid}`)
                }
                if(this.keyword) {
                    paramList.push(`keyword=${encodeURI(this.keyword)}`)
                    this.breadcrumb = this.keyword ? ('搜索：'+this.keyword) : ''
                }
                if(paramList.length) {
                    path = `${path}?${paramList.join('&')}`
                }
                var href = location.href.substring(0, location.href.lastIndexOf('#'))
                this.onRefresh()
			},
            '$props.kk': function(params){
                if(! params) return
                if(this.loading) return
                this.breadcrumb = ''
                this.categoryid = params.categoryid || undefined
                this.keyword = params.keyword || undefined
                this.pageNo = 1
                this.dataList = []
                this.visible = false

                var paramList = []
                if(this.categoryid) {
                    paramList.push(`CatID=${this.categoryid}`)
                }
                if(this.keyword) {
                    paramList.push(`keyword=${encodeURI(this.keyword)}`)
                    this.breadcrumb = this.keyword ? ('搜索：'+this.keyword) : ''
                }
                var path = this.$route.path
                if(paramList.length) {
                    path = `${path}?${paramList.join('&')}`
                }
                if(location.href.endsWith(path)) {
                    this.onRefresh()
                } else {
                    this.$router.push(path)
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    #news-list {
        position: relative;
    }

    .news-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        padding: 0px 20px 43px 20px;
        min-height: 600px;
        padding-bottom: 60px;
        position: relative;
    }

    .news-list > .news-item {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #E8E8E8;
    }

    .news-list > .news-item > .news-item-t > .news-category,
    .news-list > .news-item > .news-item-t > .news-title {
        line-height: 18px;
        line-height: 24px;
        color: #231F20;
        font-weight: bold;
        font-size: 18px;
        display: inline;
    }

    .news-list > .news-item > .news-item-t > .news-category {
        color: #FF8000;
        margin-right: 10px;
    }

    .news-list > .news-item > .news-content {
        line-height: 21px;
        font-weight: 400;
        font-size: 16px;
        color: #636363;
        margin: 12px 0px;
		text-overflow: ellipsis;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
    }

    .news-list > .news-item > .news-item-b {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
    }

    .news-list > .news-item .news-tags {
        display: flex;
        flex-direction: row;
        flex: 1;
        height: 24px;
        overflow: hidden;
    }

    .news-list > .news-item .news-tag {
        display: inline-block;
        height: 24px;
        line-height: 22px;
        padding: 0px 8px;
        background-color: #EFF7F8;
        color: #36C4D0;
        font-size: 16px;
        font-weight: bold;
        border: 1px solid #EFF7F8;
        box-sizing: border-box;
        margin-left: 16px;
    }

    .news-list > .news-item .news-tag.hide {
        display: none;
    }

    .news-list > .news-item .news-tag:nth-child(1) {
        margin-left: 0px;
    }

    .news-list > .news-item .news-tag:hover {
        border-color: #36C4D0;
        border-radius: 4px;
    }

    .news-list > .news-item .news-type {
        display: flex;
        flex-direction: row;
        height: 24px;
        line-height: 24px;
        color: #969696;
        font-size: 16px;
        font-weight: 400;
    }
    /* 文章列表  */

    // 下拉菜单
    .ant-dropdown-menu {
        height: 168px;
        overflow-y: scroll;
    }
    
    .breadcrumb {
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 42px;
        box-sizing: border-box;
        padding: 0px 16px;
        background-color: #fff;
        align-items: center;
        font-size: 16px;
        line-height: 15px;
        font-weight: 400;
        color: #969696;
        user-select: none;
    }

    .breadcrumb dt {
        margin-right: 8px;
    }

    .breadcrumb dd {
        color: #646464;
        line-height: 17px;
    }

    .breadcrumb i {
        color: #B4B4B4;
    }

    .breadcrumb a {
        margin: 0px 14px;
        text-decoration: underline;
    }

    .breadcrumb span {
        margin-left: 14px;
    }
</style>
